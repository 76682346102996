<template>
  <v-row>
    <v-col cols="12" md="4">
      <h5 class="grey100--text text-body-2" v-text="'Tier'" />
      <p class="mb-0 text--secondary lh-1-2 text-caption" v-text="transaction.data.tier" />
    </v-col>
    <v-col cols="12" md="4">
      <h5 class="grey100--text text-body-2" v-text="'Level'" />
      <p class="mb-0 text--secondary lh-1-2 text-caption" v-text="transaction.data.level" />
    </v-col>
    <v-col cols="12">
      <h5 class="grey100--text text-body-2 mb-1">
        <span v-text="'Item won'" />
      </h5>
      <div class="d-flex flex-wrap">
        <GameItem :item="item" no-item-color />
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    itemdb: {
      type: Object,
      required: true,
    },
  },
  computed: {
    item() {
      const { itemdb } = this
      const { item } = this.transaction.data

      return {
        name: item.id,
        ...itemdb[item.id],
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .game-item {
    .v-image ::v-deep .v-responsive__content {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .item-amount {
      text-shadow: 0 0 2px black;
      user-select: none;
    }
  }
</style>
